import { useState, useEffect } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";

const coverageApi = axios.create({
    withCredentials: true,
    baseURL: window.i3.coverageApiURL,    
    headers: {
        'Content-Type': 'application/json',        
    }
});
    



const AddressSearch = (props) => {    

    const [searchOptions, setSearchOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [hasSearched, setHasSearched] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        const handlePopState = () => {
          setIsProcessing(false);
        };
        
        window.addEventListener("pageshow", handlePopState);

  return () => {
    window.removeEventListener("pageshow", handlePopState);
  };
      }, []);

    useEffect(() => {
        if (searchValue.length > 5) {
            const getData = setTimeout(() => {
                coverageApi.get(`address/${searchValue}`)            
                .then(response => {
                    setSearchOptions(response.data.Response.results.slice(0, 10));
                    setHasSearched(true);
                });            
            }, 500);

            return () => clearTimeout(getData);
        }
    }, [searchValue]);

    const chooseAddress = (address) => {
        if(!address) {
            return;
        }
        setIsProcessing(true);
        const fromSearchComponent = 1;
        const referrer = window.location.pathname;
        const obj = {
            address,
            fromSearchComponent,
            referrer,
        };
        //console.log(address);
        const jsonAddress = JSON.stringify(obj);

        sessionStorage.setItem('bfAdd', jsonAddress);
 
        if(address.redirect_signup !== false) {
            window.location.assign(address.redirect_signup);
            return;
        }          
        window.location.assign(window.location.origin+'/residential-signup/');      
    }

    return (
        <div className="w-full max-w-xl mx-auto mt-2">
            <div className="address-search-container">
                <input
                    id='searchbox'
                    type="text"
                    placeholder="Street address, City, State, Zipcode"
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                        setHasSearched(false);
                    }}
                    value={searchValue}
                    className="w-full"
                />
            </div>
            {/* Results Container */}
            {(searchValue.length > 5) && (
                <div className="relative text-left mt-2">
                    {searchOptions.length > 0 ? (
                        <ul className="address-results">
                            {searchOptions.map(option => (
                                <li 
                                    key={option.id} 
                                    className="rounded-lg shadow p-4 mb-1 hover:bg-purple hover:text-white cursor-pointer transition flex justify-between" 
                                    onClick={() => chooseAddress(option)}
                                >
                                    {option.full_address}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                    </svg>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        hasSearched && (
                            <ul className="address-results">
                                <li className="rounded-lg shadow p-4 mb-1 hover:bg-purple hover:text-white cursor-pointer transition flex justify-between">
                                    Currently service unavailable at this address
                                </li>
                            </ul>
                        )
                    )}
                    {isProcessing && (
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <BeatLoader color="#ffc72d" loading={isProcessing}/>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
};

export default AddressSearch;