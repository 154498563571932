import './App.scss';
//import { ChakraBaseProvider, extendBaseTheme } from '@chakra-ui/react'
// `@chakra-ui/theme` is a part of the base install with `@chakra-ui/react`
//import chakraTheme from '@chakra-ui/theme'
import AddressSearch from './AddressSearch';

/*const theme = extendBaseTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  components: {
    Select: chakraTheme.components.Select,
    Tooltip: chakraTheme.components.Tooltip,
    // Modal: chakraTheme.components.Modal,

    Modal: chakraTheme.components.Modal,
    ModalOverlay: chakraTheme.components.ModalOverlay,
    ModalContent: chakraTheme.components.ModalContent,
    ModalHeader: chakraTheme.components.ModalHeader,
    ModalFooter: chakraTheme.components.ModalFooter,
    ModalBody: chakraTheme.components.ModalBody,
    ModalCloseButton: chakraTheme.components.ModalCloseButton,
  }
});
*/
function App() {
  return (
    
      <AddressSearch/>
  
    
  );
}

export default App;